import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

const allSignaturesQuery = graphql`
  query allSignatures {
    dataJson {
      signatures {
        type
        slug
        name
        groupe
      }
    }
  }
`

const Title = {
  textAlign: "center",
  fontFamily: "Arial",
  textTransform: "uppercase",
}
const SignatureWrapper = {
  display: "flex",
  fontFamily: "Arial",
  justifyContent: "center",
  margin: "50px",
  flexWrap: "wrap",
}
const LinkStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "10vw",
  width: "10vw",
  backgroundColor: "rgba(0,0,0,0.1)",
  margin: "50px 10px",
  padding: "20px",
  textDecoration: "none",
  textAlign: "center",
  color: "red",
  fontWeight: "bold",
  borderBottom: "solid 2px red",
}

const IndexPage = () => (
  <StaticQuery
    query={allSignaturesQuery}
    render={data => (
      <>
        <h1 style={Title}>Groupe Beyer</h1>
        <div style={SignatureWrapper}>
          {data.dataJson.signatures.map((signature, index) => (
            <div key={index}>
              <Link
                style={Object.assign({}, LinkStyle, {
                  borderBottomColor: "#153468",
                  color: "#153468",
                })}
                to={`/signatures/${signature.slug}`}
              >
                {signature.groupe}
              </Link>
            </div>
          ))}
        </div>
      </>
    )}
  />
)

export default IndexPage
